import React from "react"
import { graphql } from "gatsby"
import BookLink from "../components/bookLink"
import Layout from "../components/layout"
import SEO from "../components/seo"
import config from "../../config.json"

const IndexPage = ({
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const Posts = edges
    .filter(edge => !!edge.node.frontmatter.date)
    .map(edge => (
      <li
        style={{
          padding: "0",
          display: "flex",
          margin: "0",
          textDecoration: "none",
          listStyle: "none",
          width: "200px",
          marginBottom: "40px",
        }}
      >
        <BookLink key={edge.node.id} post={edge.node} />
      </li>
    ))

  return (
    <Layout>
      <SEO
        title={`${config.author}'s free work`}
        description={`Read ${config.author}'s books and short stories for free, online, ebook!`}
      />
      <h1>{config.author}'s Public Domain Work</h1>
      <p>
        Philip Kindred Dick (December 16, 1928 – March 2, 1982) was an American
        writer known for his work in science fiction. He has produced 44
        published novels and approximately 121 short stories, most of which
        appeared in science fiction magazines during his lifetime. His fiction
        explored varied philosophical and social themes, and featured recurrent
        elements such as alternate realities, simulacra, large corporations,
        drug abuse, authoritarian governments, and altered states of
        consciousness.
      </p>
      <ul
        style={{
          display: "flex",
          margin: "0",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          flexWrap: "wrap",
        }}
      >
        {Posts}
      </ul>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
          }
        }
      }
    }
  }
`
