import React from "react"
import { Link } from "gatsby"
import ThemeContext from "../context/theme"

const BookLink = ({ post }) => (
  <ThemeContext.Consumer>
    {theme => (
      <Link
        style={{
          borderBottom: `2px dotted ${theme.dark ? "white" : `#212529`}`,
          textDecoration: "none",
          color: theme.dark ? "white" : `#212529`,
        }}
        to={post.frontmatter.path}
      >
        {post.frontmatter.title}
      </Link>
    )}
  </ThemeContext.Consumer>
)

export default BookLink
